import ko from 'knockout';
import cookie from 'cookie';
import { nanoid } from 'nanoid';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import QRCode from 'qrcode-svg';

import 'op-flex/op-flex.css';
import './main.css';

Chart.register(ChartDataLabels);

const colours = [
    '#281a11',
    '#0f1e2e',
    '#28111b',
    '#282714',
    '#000000',
    '#0b0b1f',
    '#280606',
    '#1d2510',
    '#0b1918',
    '#201023',
    '#082111',
    '#130f28',
    '#142121',
    '#1f1212',
    '#2e0915',
    '#1c0028',
    '#102a1d',
    '#282300',
    '#282020',
    '#111111'
];

const chartPresets = {
    pie: {
        type: 'pie',
        options: {
            color: '#999',
            borderColor: '#444',
            backgroundColor: colours,
            plugins: {
                legend: false,
                tooltip: {
                    enabled: false
                },
                datalabels: {
                    color: '#999',
                    font: {
                        size: '16px',
                        family: '"Ubuntu Mono", monospace'
                    },
                    anchor: 'end',
                    align: 'start',
                    offset: 30,
                    formatter: (value, context) => {
                        if (!value) return '';
                        return context.chart.data.labels[context.dataIndex] + ': ' + (value < 1 ? 0 : value);
                    }
                }
            }
        }
    }
};

ko.bindingHandlers['obChart'] = {
    init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
        const params = ko.utils.unwrapObservable(valueAccessor());
        if (typeof params !== 'object') return;

        const options = chartPresets[params.preset];

        // yes this does overwrite the preset object.. oh well
        Object.keys(params).forEach((key) => {
            if (key === 'preset') return;
            options[key] = ko.utils.unwrapObservable(params[key]);
        });

        const chart = new Chart(element, options);

        // let dataSub;
        // if (ko.isObservable(params.data)) {
        //     dataSub = params.data.subscribe((newSeries) => {
        //         chart.updateSeries(newSeries);
        //     });
        // }

        // ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
        //     if (dataSub) dataSub.dispose();
        //     chart.destroy();
        // });
    }
};

const qrPreset = {
    width: 256,
    height: 256,
    padding: 0,
    container: 'svg-viewbox',
    join: true,
    ecl: 'L',
    color: '#aaa',
    background: '#000'
};

ko.bindingHandlers['obQRCode'] = {
    init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
        const params = ko.utils.unwrapObservable(valueAccessor());
        if (typeof params !== 'object') return;

        const options = qrPreset;
        Object.keys(params).forEach((key) => options[key] = ko.utils.unwrapObservable(params[key]));

        const qr = new QRCode(options).svg();
        element.innerHTML = qr;
    }
};

class OBSocket {
    constructor(boardID, receivers) {
        this.boardID = boardID;
        this.receivers = receivers;

        this.socket = null;
        // window.ob_socket = this.socket;

        this.lastPing = 0;
        this.lastJoin = 0;
        this.lastUserActivity = 0;

        this.requests = {};

        this.userActivityTimeout = 1800000; // half hour

        this.connectionStatus = ko.observable({pending: true});

        document.addEventListener('mousemove', this.userActivity, false);
        document.addEventListener('mousedown', this.userActivity, false);
        document.addEventListener('keypress', this.userActivity, false);
        document.addEventListener('touchmove', this.userActivity, false);
    }

    start = async () => {
        // cant set cookies from websockets, verify the account before connecting

        if (cookie.parse(document.cookie).acct) {
            try {
                await fetch(window.location.protocol + '//' + window.location.host + '/api/user');
            } catch (err) {
                console.error(err);
            }
        }

        if (!cookie.parse(document.cookie).acct) {
            try {
                await fetch(window.location.protocol + '//' + window.location.host + '/api/user', {method: 'POST'});
                // TODO: verify cookie was set
            } catch (err) {
                console.error(err);
                // TODO: display errors, especially invalid country
            }
        }

        this.join();

        clearInterval(this.rejoinInterval);
        this.rejoinInterval = setInterval(this.checkStatus, 4000);
    }

    join = () => {
        const now = Date.now();

        if (this.lastUserActivity && now - this.lastUserActivity > this.userActivityTimeout) return;

        if (now - this.lastJoin < 10000) return;
        this.lastJoin = now;

        console.log('socket join attempt');

        try {
            if (this.socket) {
                console.log('possible existing socket, closing');
                this.close();
            }

            // allow non-ssl websockets for dev environment
            const protocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
            this.socket = new WebSocket(protocol + '//' + window.location.host + '/api/socket/' + this.boardID);

            this.socket.addEventListener('message', this.receive);
            this.socket.addEventListener('open', this.opened);
            this.socket.addEventListener('close', this.closed);
            this.socket.addEventListener('error', this.error);
        } catch (err) {
            console.error(err);
        }
    }

    close = () => {
        this.socket.removeEventListener('message', this.receive);
        this.socket.removeEventListener('open', this.opened);
        this.socket.removeEventListener('close', this.closed);
        this.socket.removeEventListener('error', this.error);

        this.socket.close();

        this.connectionStatus({error: 'socket closed'});
    }

    request = (data) => {
        if (!data || typeof data !== 'object') return;

        return new Promise((resolve, reject) => {
            const stateID = data.stateID = nanoid();

            const requestTimeout = setTimeout(reject, 10000, 'request timed out');

            this.requests[stateID] = {id: stateID, ts: Date.now(), resolve: (responseData) => {
                clearTimeout(requestTimeout);
                resolve(responseData);
            }};

            console.log('socket send', data);

            try {
                this.socket.send(JSON.stringify(data));
            } catch (err) {
                clearTimeout(requestTimeout);
                reject(err);
            }
        });
    }

    receive = (event) => {
        const response = JSON.parse(event.data);

        console.log('socket receive', response);

        const requestState = this.requests[response.stateID];

        if (requestState) requestState.resolve(response);

        if (response.type && this.receivers[response.type]) this.receivers[response.type](response, event);

        this.connectionStatus({connected: true, ts: Date.now()});
    }

    opened = (event) => {
        console.log('socket connected', event);
        if (this.receivers['connect']) this.receivers['connect'](event);
    }

    closed = (event) => {
        console.log('socket closed', event);
        this.connectionStatus({error: 'socket closed'});
        this.join();
    }

    error = (event) => {
        console.log('socket error', event);
        this.connectionStatus({error: 'socket error'});
        this.close();
    }

    checkStatus = () => {
        if (this.socket?.readyState > 1) {
            console.log('bad socket');
            this.connectionStatus({error: 'socket error'});
            this.join();
            return;
        }

        const currentStatus = this.connectionStatus();
        const now = Date.now();

        if (currentStatus.connected && this.lastUserActivity && now - this.lastUserActivity > this.userActivityTimeout) {
            this.close();
        } else if (currentStatus.connected && now - this.lastPing > 42000) {
            this.lastPing = now;
            this.request({type: 'ping'});
        } else if (now - currentStatus.ts > 48000 && this.lastPing && currentStatus.ts - this.lastPing > 6000) {
            console.log('ping error');
            this.connectionStatus({error: 'ping error'});
            this.join();
        }
    }

    userActivity = (event) => {
        const now = Date.now();

        if (this.lastUserActivity && now - this.lastUserActivity > this.userActivityTimeout) {
            this.lastUserActivity = now;
            this.join();
        }

        this.lastUserActivity = now;
    }
}

class OBPoll {
    constructor(params) {
        this.type = 'poll';

        this.colours = colours;
        this.alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

        this.selected = ko.observable('');

        const config = params.config;
        const results = params.results;

        let pieData = [];
        let pieLabels = [];

        this.title = ko.observable(config.title);

        let allZero = true;

        for (let i = 0; i < config.choices.length; i++) {
            const choice = config.choices[i];

            choice.title = ko.observable(choice.title);

            if (typeof results[choice.id] === 'number') {
                pieData.push(results[choice.id]);
                pieLabels.push(this.alphabet[i]);

                if (allZero && results[choice.id]) allZero = false;
            }
        }

        if (allZero) pieData = pieData.map(() => 0.1);

        this.choices = ko.observableArray(config.choices);

        this.pieData = ko.observableArray(pieData);
        this.pieLabels = ko.observableArray(pieLabels);
    }

    selectChoice = (choice) => {
        if (this.selected() === choice.id) {
            this.selected(null);
            return;
        }

        this.selected(choice.id);
    }

    addChoice = () => {
        if (this.choices().length >= 20) return;
        this.choices.push({
            title: ko.observable('')
        });
    }

    removeChoice = () => {
        if (this.choices().length <= 2) return;
        this.choices.pop();
    }

    toJSON = () => {
        return ko.toJS({
            type: this.type,
            title: this.title,
            choices: this.choices
        });
    }
}

class OakboardApp {
    constructor() {
        const boardID = window.location.pathname.slice(1).split('/')[0];

        this.api = new OBSocket(boardID, {connect: this.requestState, state: this.receiveState});

        this.editing = ko.observable(false);

        this.config = ko.observable({});
        this.results = ko.observable({});

        this.user = ko.observable('');
        this.ballot = ko.observable(null);
        this.isOwner = ko.observable(false);

        this.poll = ko.observable();

        if (boardID) this.api.start();
        else this.config({type: 'none'});
    }

    requestState = () => {
        this.api.request({type: 'state'});
    }

    receiveState = (response) => {
        if (!response || typeof response !== 'object') return;

        // initial setup and establish ownership
        if (!response.config.type) this.api.request({type: 'configure', config: {type: 'poll', choices: [{}, {}, {}]}});

        if (response.config.type === 'poll' && !this.editing()) {
            this.poll(new OBPoll({config: response.config, results: response.pollResults}));
        }

        this.config(response.config);
        this.results(response.pollResults);

        const ballot = response.ballot || this.ballot();

        if (ballot && response.config?.choices) {
            let validBallot = false;
            response.config.choices.forEach((choice) => {
                if (choice.id === ballot.choice) validBallot = true;
            });

            if (validBallot) this.ballot(ballot);
            else this.ballot(null);
        }

        if (response.user) this.user(response.user);
        if (response.ownerID) this.isOwner(response.ownerID === this.user());
    }

    sendVote = async (choiceID) => {
        if (!choiceID) return;
        const response = await this.api.request({type: 'vote', choice: choiceID});
        console.log(response);
    }

    sendConfig = () => {
        this.editing(false);
        this.api.request({type: 'configure', config: this.poll()});
    }

    copyLink = async (data, event) => {
        try {
            await navigator.clipboard.writeText(window.location);
            event.target.style.backgroundColor = '#779542';
            event.target.textContent = 'Copied ✓';
        } catch (err) {
            event.target.style.backgroundColor = '#ae3333';
            event.target.textContent = 'Error';
        }

        clearTimeout(this.copyTimeout);
        this.copyTimeout = setTimeout(() => {
            event.target.style.backgroundColor = null;
            event.target.textContent = 'Copy';
        }, 2000);
    }
}

ko.options.deferUpdates = true;

ko.components.register('ob-spinner', {template: {element: 'ob-spinner-template'}});
ko.components.register('ob-poll', {template: {element: 'ob-poll-template'}});

window.ko = ko;
window.ko_app = new OakboardApp();
ko.applyBindings(window.ko_app, document.body);
